import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import React from 'react';
import Modal from './Modal';
import {PrimaryButton} from './index';
import {deleteCompany} from '../helpers/queryFns/company';

export default function DeleteCompanyModal({open, setOpen, companyData}) {
  const queryClient = useQueryClient();

  const {mutate: deleteCompanyData, isLoading: updateLoading} = useMutation(
    deleteCompany,
    {
      onSuccess: () => {
        setOpen(false);
        toast.success('Company deleted successfully!');
        queryClient.invalidateQueries('companyInfo-query');
        queryClient.invalidateQueries('company-data');
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );

  const handleDeleteCompany = e => {
    e.preventDefault();
    deleteCompanyData(companyData?.id);
  };

  return (
    <Modal
      show={open}
      modalTitle={`Delete - ${companyData?.name}?`}
      onClose={() => setOpen(false)}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form onSubmit={handleDeleteCompany} className="mt-6 text-left">
        <p className="text-sm text-grey60 text-center">
          Are you sure you want to delete this company?
        </p>

        <div className="mt-6 flex flex-col gap-6">
          <PrimaryButton
            fontSize="text-sm"
            buttonText="Yes, Delete Company"
            className="inline-flex justify-center w-full mt-6 text-white rounded bg-brandBlue"
            loadingState={updateLoading}
            disabled={updateLoading}
            bgColor="bg-danger"
            bgHover="bg-danger100"
          />

          <button
            type="button"
            className="text-grey60 text-tiny"
            onClick={() => {
              setOpen(false);
            }}
          >
            No, cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
